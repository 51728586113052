<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import PolicyWithItems from "@/components/ui/baseComponents/policies/PolicyWithItems.vue";
import Swal from "sweetalert2";

const store = useStore();
const router = useRouter();
const route = useRoute();
if (route.params.id) {
  store.dispatch("MAgeneralModule/fetchSingleInductionChecklist", {
    induction_checklist: route.params.id,
  });
}
const singleChecklist = computed(() => {
  return store.getters["MAgeneralModule/getSingleInductionChecklist"];
});

const savePolicy = async (data) => {
  await store.dispatch("MAgeneralModule/saveInductionChecklist", data);
  Swal.fire({
    title: "Done",
    text: "Induction Policy Created Successfully",
    icon: "success",
  });
  router.push({ name: "ma-staff-induction" });
};
</script>
<template>
  <PolicyWithItems
    @savePolicy="savePolicy"
    :accaptQuestions="false"
    :policyData="singleChecklist || {}"
  />
</template>